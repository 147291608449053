.container {
    margin: 40px auto 0 auto;
    position: relative;
    height: 500px;
    max-width: var(--unit-content);
    overflow-y: visible;
    overflow-x: clip;
}

.container .projectCard1 {
    position: absolute;
    left: calc(50% - 320px);
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.container .projectCard2 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.container .projectCard3 {
    position: absolute;
    left: calc(50% + 320px);
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.line {
    position: absolute;
    top: 0;
    left: calc(50% - var(--unit-content) / 2 + 85px);
}

.star {
    position: absolute;
    top: 0;
    right: calc(50% - var(--unit-content) / 2);
}

.icon1 {
    position: absolute;
    top: 298px;
    left: calc(50% - var(--unit-content) / 2 - 5px);
    z-index: 3;
}

.icon2 {
    position: absolute;
    left: calc(50% + 170px);
    top: calc(50% - 194px);
    z-index: 3;
    transform: translate(-50%, -50%);
}

.button {
    display: inline-flex;
    border: none;
    border-radius: 100px;
    height: 64px;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    position: absolute;
    top: 410px;
    right: calc(50% - var(--unit-content) / 2);
    padding: 0 27px;
    transform: rotateZ(10deg);
    color: var(--color-white);
    background: var(--color-main);
    z-index: 3;
}

.button img {
    margin-left: 4px;
}

@media screen and (max-width: 1172px) {

    .container {
        margin-top: 0;
        height: 376px;
        z-index: -1;
    }

    .container .projectCard2 {
        transform: translate(-50%, -50%) scale(0.65);
    }

    .container .projectCard1 {
        left: calc(50% - 160px);
    }

    .container .projectCard3 {
        left: calc(50% + 160px);
    }

    .container .projectCard1,
    .container .projectCard3 {
        transform: translate(-50%, -50%) scale(0.45);
    }

    .line {
        top: calc(50% - 215px);
        left: calc(50% - 215px);
        transform: scale(0.569620253);
    }

    .star {
        top: -80px;
        right: calc(50% - 300px);
        transform: scale(0.569620253);
    }

    .icon1 {
        position: absolute;
        top: calc(50% + 35px);
        left: calc(50% - 260px);
        transform: scale(0.5);
    }

    .icon2 {
        left: calc(50% + 120px);
        top: calc(50% - 120px);
        transform: translate(-50%, -50%) scale(0.8);
    }

    .container .button {
        top: 235px;
        right: calc(50% - 240px);
        transform: rotateZ(10deg) scale(0.5);
    }

}

@media screen and (max-width: 768px) {

    .container {
        margin-top: -20px;
        margin-bottom: -50px;
    }

    .container .projectCard1 {
        left: calc(50% - 140px);
    }

    .container .projectCard3 {
        left: calc(50% + 140px);
    }

    .line {
        top: calc(50% - 215px);
    }

    .icon1 {
        position: absolute;
        top: calc(50% + 20px);
        left: calc(50% - 240px);
        transform: scale(0.5);
    }

    .icon2 {
        top: calc(50% - 105px);
        left: calc(50% + 100px);
        transform: translate(-50%, -50%) scale(0.8);
    }

    .container .button {
        top: calc(50% + 40px);
        right: calc(50% - 240px);
        transform: rotateZ(10deg) scale(0.5);
    }

}
