.main {
    width: 100%;
    height: 100vh;
    background: var(--color-background-1);
}

.content {
    width: 100%;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.text {
    color: var(--color-white);
    text-align: center;
}

.textTitle {
    margin: 0;
    font-size: 64px;
}

.textTitleMobile {
    display: none;
    margin: 0;
    font-size: 32px;
}

.textDescription {
    margin: 32px 0 40px 0;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.8);
}

.br {
    display: none;
}

@media screen and (max-width: 1172px) {

    .textTitle {
        font-size: 48px;
    }

    .textDescription {
        font-size: 20px;
    }

}

@media screen and (max-width: 768px) {

    .textTitle {
        font-size: 32px;
    }

    .textDescription {
        font-size: 15px;
    }

    .br {
        display: inline;
    }

}
