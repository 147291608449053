.section {
    width: 100%;
    background: var(--color-main);
    text-align: center;
    padding: 140px 0;
}

.sectionContent {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: var(--unit-content);
    padding: var(--unit-content-padding);
}

.title {
    color: var(--color-white);
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 1.1666666666;
}

.description {
    color: var(--color-white);
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-top: 0;
    margin-bottom: 121px;
    line-height: 1.5;
}

.article > .steps > div + div {
    margin-left: 20px;
}

.article > .steps > div:nth-of-type(3) {
    position: relative;
    bottom: 60px;
}

.swiper {
    display: none;
    width: 270px;
    margin: 0 auto;
}

.swiper :global(.swiper) {
    overflow: visible;
}

.swiperIndices {
    display: none;
    margin-top: 32px;
}

.swiperIndex {
    width: 10px;
    height: 10px;
    display: inline-block;
    background: var(--color-white);
    border-radius: 10px;
    opacity: 0.3;
}

.swiperIndex + .swiperIndex {
    margin-left: 12px;
}

.swiperIndex.active {
    opacity: 1;
}

@media screen and (max-width: 1172px) {

    .article {
        overflow: visible;
    }

    .steps {
        display: none;
    }

    .swiper {
        display: block;
    }

    .swiperIndices {
        display: block;
    }

}

@media screen and (max-width: 768px) {

    .section {
        padding: 60px 0;
    }

    .title {
        font-size: 32px;
        margin-bottom: 16px;
    }

    .description {
        font-size: 20px;
        margin-bottom: 53px;
    }

}

@media screen and (max-width: 480px) {

    .title {
        font-size: 24px;
    }

    .description {
        font-size: 15px;
    }

}
