.section {
    width: 100%;
    background: var(--color-background-1);
    padding: 80px 0;
}

.sectionContent {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: var(--unit-content);
    padding: var(--unit-content-padding);
}

.article {
    position: relative;
    width: 100%;
    height: 460px;
    box-sizing: border-box;
    padding: 80px 60px;
    border-radius: 40px;
    background: var(--color-main);
    text-align: left;
    overflow: hidden;
}

.content {
    position: absolute;
    z-index: 1;
}


.title {
    color: var(--color-white);
    font-size: 48px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 1;
}

.description {
    color: var(--color-white);
    font-size: 24px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 40px;
    line-height: 1;
}

.square1 {
    position: absolute;
    width: 560px;
    height: 560px;
    left: -160px;
    top: -200px;
    background: linear-gradient(180.37deg, #2240DE -0.1%, #4A67FF 1.88%, rgba(74, 103, 255, 0) 84.24%);
    opacity: 0.4;
    filter: drop-shadow(0px 4px 80px rgba(0, 0, 0, 0.08));
    border-radius: 50px;
    transform: rotate(160.52deg);
}

.square2 {
    position: absolute;
    width: 440px;
    height: 440px;
    right: -80px;
    bottom: -130px;
    background: linear-gradient(180.46deg, #2240DE 0.78%, #4A67FF 2.74%, rgba(74, 103, 255, 0) 84.19%);
    opacity: 0.7;
    filter: drop-shadow(0px 4px 80px rgba(0, 0, 0, 0.08));
    border-radius: 50px;
    transform: rotate(-12deg);
}

.computer {
    position: absolute;
    bottom: 64px;
    right: 55px;
}

@media screen and (max-width: 1172px) {

    .computer {
        width: 300px;
    }

    .square1 {
        width: 440px;
        height: 440px;
        left: -100px;
    }

    .square2 {
        width: 350px;
        height: 350px;
    }

}

@media screen and (max-width: 768px) {

    .section {
        padding: 60px 0;
    }

    .article {
        padding: 32px 24px;
        border-radius: 24px;
        height: 350px;
    }

    .title {
        font-size: 32px;
        margin-bottom: 12px;
    }

    .description {
        font-size: 20px;
        margin-bottom: 16px;
    }

    .computer {
        width: 263px;
        right: 23px;
        bottom: 43px;
    }

    .square1 {
        width: 328px;
        height: 328px;
        top: -120px;
        left: -80px;
    }

    .square2 {
        width: 251px;
        height: 251px;
        bottom: -60px;
    }

}

@media screen and (max-width: 480px) {

    .title {
        font-size: 24px;
    }

    .description {
        font-size: 15px;
    }

    .square1 {
        top: -110px;
        left: -100px;
    }

    .square2 {
        bottom: -60px;
        right: -50px;
    }

}
