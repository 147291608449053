.footer {
    background: var(--color-background-3);
    font-weight: 400;
}

.content {
    display: flex;
    margin: 0 auto;
    align-items: flex-start;
    justify-content: space-between;
    max-width: var(--unit-content);
    padding: 80px 16px;
}

.contact {
    display: flex;
    flex-direction: column;
    color: #81818c;
    font-size: 15px;
    font-weight: 400;
}

.contactText + .contactText {
    margin-top: 14px;
}

.copyright {
    margin-top: 24px;
    font-weight: 600;
    line-height: 1;
}

@media screen and (max-width: 1172px) {

    .content {
        padding: 40px;
    }

    .contactText {
        line-height: 2;
    }

    .contactText + .contactText {
        margin-top: 0;
    }

    .dot {
        display: none;
    }

}

@media screen and (max-width: 768px) {

    .content {
        padding: 40px 16px;
        flex-direction: column;
    }

    .logo {
        width: 131px;
    }

    .contact {
        margin-top: 13px;
        font-size: 13px;
    }

    .copyright {
        margin-top: 13px;
    }


}
