.section {
    width: 100%;
    background: var(--color-background-1);
    padding: 140px 0;
}

.sectionContent {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    max-width: var(--unit-content);
    padding: var(--unit-content-padding);
}

.title {
    color: var(--color-white);
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 1.1666666666;
}

.description {
    color: var(--color-gray-4);
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-top: 0;
    margin-bottom: 60px;
    line-height: 1.5;
}

.article {
    width: 100%;
}

@media screen and (max-width: 768px) {

    .section {
        padding: 60px 0;
    }

    .title {
        font-size: 32px;
        margin-bottom: 16px;
    }

    .description {
        font-size: 20px;
    }

}

@media screen and (max-width: 480px) {

    .title {
        font-size: 24px;
    }

    .description {
        font-size: 15px;
    }

}
