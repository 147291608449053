.card {
    position: relative;
    background-color: var(--color-white);
    border-radius: 24px;
    overflow: hidden;
    padding: 32px 24px;
    text-align: left;
    box-sizing: border-box;
    width: 380px;
    display: inline-block;
}

.title {
    margin: 24px 0;
    font-size: 24px;
    font-weight: 600;
}

.description {
    display: flex;
    margin-top: 12px;
    font-size: 18px;
}

.description div:first-of-type {
    width: 68px;
    color: var(--color-gray-4);
    margin-right: 12px;
}

.description div:last-of-type {
    flex: 1;
}

.tagContainer {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px dashed var(--color-gray-3);
}

.tag {
    display: inline-block;
    background-color: rgba(74, 103, 255, 0.2);
    min-width: 72px;
    box-sizing: border-box;
    padding: 10px 8px;
    line-height: 1;
    color: var(--color-main);
    font-size: 18px;
    border-radius: 8px;
    text-align: center;
}

.tag + .tag {
    margin-left: 8px;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 92px;
    height: 92px;
    border-radius: 92px;
    background: var(--color-background-2);
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.compact.card {
    width: 320px;
}

.compact .title {
    margin: 20px 0;
}

.compact .icon {
    width: 77px;
    height: 77px;
}

.compact .tag {
    min-width: 60px;
}

@media screen and (max-width: 768px) {

    .card {
        padding: 24px 16px;
        width: 296px;
    }

    .icon {
        width: 72px;
        height: 72px;
    }

    .title {
        margin-bottom: 16px;
        font-size: 18px;
    }

    .description {
        font-size: 15px;
    }

    .tag {
        padding: 8px 9px;
        font-size: 15px;
        min-width: 56px;
    }

}
