.section {
    width: 100%;
    background: var(--color-background-1);
    padding: 140px 0;
}

.sectionContent {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: var(--unit-content);
    padding: var(--unit-content-padding);
}

.title, .titleMobile {
    color: var(--color-white);
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    margin-top: 0;
    margin-bottom: 40px;
    line-height: 66px;
}

.titleMobile {
    display: none;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-top: 0;
    margin-bottom: 32px;
    line-height: 1;
}

.titleMobile div {
    line-height: 36px;
}

:is(.title, .titleMobile) mark {
    color: var(--color-white);
    background: rgba(74, 103, 255, 0.4);
}

.contentBox {
    color: var(--color-gray-2);
    background: var(--color-background-2);
    height: 592px;
    width: 100%;
    padding: 60px;
    border-radius: 40px;
    box-sizing: border-box;
    text-align: center;
}

.contentBoxDescription, .contentBoxDescriptionMobile {
    margin-top: 0;
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5;
}

.contentBoxDescriptionMobile {
    display: none;
    margin-bottom: 24px;
}

.contentBoxColumnContainer {
    display: flex;
    position: relative;
    height: 360px;
}

.contentBoxColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    height: 100%;
    padding: 24px 40px 40px 40px;
    background: var(--color-background-3);
    border-radius: 32px;
    box-sizing: border-box;
}

.contentBoxColumn + .contentBoxColumn {
    margin-left: 20px;
}

.contentBoxColumnArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #09090B;
    width: 64px;
    height: 64px;
    border-radius: 64px;
}

.contentBoxImageMobile {
    display: none;
}

.contentBoxColumnTitle {
    font-size: 20px;
    line-height: 1;
}

.contentBoxColumnDescription {
    margin-top: 12px;
    font-size: 16px;
    line-height: 1.5;
    color: var(--color-gray-4);
    font-weight: 400;
}

@media screen and (max-width: 1172px) {

    .section {
        padding: 60px 0;
    }

    .contentBox {
        padding: 32px 16px;
        height: auto;
    }

    .contentBoxColumnContainer {
        flex-direction: column;
        height: auto;
    }

    .contentBoxColumnArrow {
        width: 54px;
        height: 54px;
    }

    .contentBoxColumnArrow img {
        transform: rotateZ(90deg);
    }

    .contentBoxColumn {
        display: flex;
        justify-content: center;
        padding: 20px 10px;
        height: 294px;
        flex: unset;
    }

    .contentBoxColumn + .contentBoxColumn {
        margin-left: 0;
        margin-top: 24px;
    }

    .contentBoxImage {
        display: none;
    }

    .contentBoxImageMobile {
        display: inline;
    }

    .contentBoxText {
        margin-top: 24px;
    }

}

@media screen and (max-width: 768px) {

    .title {
        font-size: 32px;
        margin-bottom: 16px;
        line-height: 48px;
    }

    .contentBoxDescription {
        font-size: 20px;
    }

    .br {
        display: inline;
    }

    .contentBoxColumnDescription {
        font-size: 13px;
    }

}

@media screen and (max-width: 480px) {

    .title {
        display: none;
    }

    .titleMobile {
        display: block;
    }

    .contentBoxDescription {
        font-size: 15px;
        display: block;
    }


}
