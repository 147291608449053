.button {
    display: inline-flex;
    color: #000000;
    background: var(--color-white);
    border: none;
    border-radius: 100px;
    height: 64px;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    padding: 0 48px;
}

.image {
    margin-left: 4px;
}

.imageMobile {
    display: none;
    margin-left: 4px;
}

@media screen and (max-width: 768px) {

    .button {
        height: 40px;
        font-size: 15px;
        padding: 0 20px;
    }

    .image {
        display: none;
    }

    .imageMobile {
        display: inline;
    }

}
