.card {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 45px 20px 20px 20px;
    border-radius: 24px;
    background-color: var(--color-white);
    width: 270px;
    height: 340px;
    box-sizing: border-box;
}

.step {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    border-radius: 100px;
    height: 42px;
    width: 88px;
    line-height: 42px;
    color: var(--color-white);
    font-size: 16px;
    font-weight: 700;
    background-color: var(--color-point-1);
}

.title {
    color: var(--color-black);
    line-height: 1;
    font-size: 24px;
    font-weight: 700;
}

.description {
    margin-top: 12px;
    color: var(--color-gray-5);
    font-size: 18px;
    line-height: 1;
}

.description.highlight {
    margin-top: 8px;
    padding: 6px;
    background-color: rgba(74, 103, 255, 0.2);
    color: var(--color-main);
    border-radius: 6px;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    margin-top: 24px;
    border-top: 1px dashed var(--color-gray-3);
    font-size: 20px;
    font-weight: 600;
    line-height: 1.75;
}
