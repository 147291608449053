.section {
    width: 100%;
    background: var(--color-background-1);
    text-align: center;
    padding: 140px 0;
}

.sectionContent {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: var(--unit-content);
    padding: var(--unit-content-padding);
}

.title {
    color: var(--color-white);
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 1.1666666666;
}

.description {
    color: var(--color-gray-4);
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-top: 0;
    margin-bottom: 8px;
    line-height: 1.5;
}

.warning {
    color: var(--color-gray-5);
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    margin-top: 0;
    margin-bottom: 60px;
    line-height: 1.5;
}

.moneyContainer {
    display: flex;
    height: 260px;
    width: 100%;
    margin: 0 auto;
    color: var(--color-white);
}

.money {
    display: flex;
    flex: 1;
    height: 100%;
    padding: 70px 60px;
    box-sizing: border-box;
    border-radius: 24px;
    background: var(--color-background-2);
}

.money + .money {
    margin-left: 20px;
}

.moneyImage {
    width: 120px;
    height: 120px;
    border-radius: 120px;
    margin-right: 20px;
    background: var(--color-gray-6);
}

.moneyText {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: left;
}

.moneyText span {
    display: block;
}

.moneyText span:first-of-type {
    font-size: 32px;
    line-height: 1;
    margin-bottom: 8px;
    color: var(--color-gray-4);
}

.moneyText span:last-of-type {
    font-size: 48px;
    line-height: 80px;
}

.moneyText span:last-of-type b {
    font-size: 80px;
}

@media screen and (max-width: 1172px) {

    .moneyContainer {
        height: auto;
        max-width: 800px;
    }

    .money {
        display: flex;
        align-items: center;
        padding: 34px 16px;
        height: 148px;
    }

    .moneyImage {
        width: 80px;
        height: 80px;
    }

    .moneyText span:first-of-type {
        font-size: 20px;
    }

    .moneyText span:last-of-type {
        font-size: 32px;
        line-height: 44px;
    }

    .moneyText span:last-of-type b {
        font-size: 44px;
    }

}

@media screen and (max-width: 768px) {

    .section {
        padding: 60px 0;
    }

    .title {
        font-size: 32px;
        margin-bottom: 16px;
    }

    .description {
        font-size: 20px;
    }

    .warning {
        font-size: 16px;
        margin-bottom: 32px;
    }

}

@media screen and (max-width: 640px) {

    .moneyContainer {
        flex-direction: column;
    }

    .money + .money {
        margin-top: 20px;
        margin-left: 0;
    }

}

@media screen and (max-width: 480px) {

    .title {
        font-size: 24px;
    }

    .description {
        font-size: 15px;
    }

    .warning {
        font-size: 12px;
    }

}
