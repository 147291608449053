.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--color-background-1);
    height: var(--unit-header-height);
    z-index: var(--z-index-header);
}

.headerContent {
    display: flex;
    height: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    max-width: var(--unit-content);
    box-sizing: border-box;
    padding: 0 50px;
}

.buttons {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
}

.button {
    color: var(--color-white);
    cursor: pointer;
    padding: 10px 12px;
    font-size: 16px;
    line-height: 16px;
    text-decoration: none;
    margin-right: 16px;
}

.buttonBlue {
    padding: 12px 24px;
    height: 40px;
    border: none;
    border-radius: 100px;
    line-height: 1;
    font-size: 16px;
    font-weight: 500;
    box-sizing: border-box;
    background: var(--color-main);
    color: var(--color-white);
    cursor: pointer;
    text-decoration: none;
}

@media screen and (max-width: 768px) {

    .logo {
        width: 116px;
    }

    .headerContent {
        padding: 0 16px;
    }

    .buttonBlue {
        padding: 10px 16px;
        height: 34px;
        font-size: 15px;
    }

}

@media screen and (max-width: 480px) {

    .button {
        display: none;
    }

}
