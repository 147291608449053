@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard.css");
@import url('https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&display=swap');

:root {

  --color-main: #0000cc;
  --color-point-1: #4a67ff;
  --color-point-2: #7a9fff;
  --color-background-1: #09090b;
  --color-background-2: #17171c;
  --color-background-3: #222228;
  --color-white: #ffffff;
  --color-gray-1: #fafafa;
  --color-gray-2: #f7f7f7;
  --color-gray-3: #dfdfdf;
  --color-gray-4: #bebebe;
  --color-gray-5: #6b6b6b;
  --color-gray-6: #444444;
  --color-black: #1a1a1a;

  --unit-header-height: 72px;
  --unit-content: 1140px;
  --unit-content-padding: 0 16px;

  --z-index-header: 99;
  --z-index-modal: 999;

}

body {
  margin: 0;
  font-size: 15px;
  font-family: 'Pretendard', 'Noto Color Emoji', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
}

#root {
  width: 100%;
  overflow-x: hidden;
}

.br1172 {
  display: none;
}

.br768 {
  display: none;
}

.br480 {
  display: none;
}

.br360 {
  display: none;
}

@media screen and (max-width: 1172px) {

  .br1172 {
    display: inline;
  }

}

@media screen and (max-width: 768px) {

  .br768 {
    display: inline;
  }

}

@media screen and (max-width: 480px) {

  .br480 {
    display: inline;
  }

}

@media screen and (max-width: 360px) {

  .br360 {
    display: inline;
  }

}
