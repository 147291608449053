.modalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: var(--z-index-modal);
    overflow-y: auto;
    overscroll-behavior: contain;
}

.modalContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    padding: 40px;
    box-sizing: border-box;
}

.modal {
    position: relative;
    border-radius: 32px;
    background: var(--color-white);
    width: 600px;
    display: flex;
    flex-direction: column;
}

.modalCloseButton {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.modalHeader {
    padding: 40px;
    text-align: center;
}

.modalHeaderTitle {
    line-height: 1;
    font-size: 32px;
    font-weight: 700;
}

.modalHeaderSubTitle {
    line-height: 23px;
    font-size: 18px;
    margin-top: 16px;
    color: var(--color-gray-6);
}

.modalBody {
    padding: 0 40px;
    overscroll-behavior: contain;
}

.input, .select {
    display: block;
    padding: 22px 20px;
    line-height: 1;
    font-size: 16px;
    font-weight: 500;
    color: var(--color-gray-6);
    border: 1px solid var(--color-gray-3);
    border-radius: 8px;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    background-color: var(--color-white);
}

.input:disabled, .select:disabled {
    color: var(--color-gray-4);
    background-color: var(--color-gray-2);
    border-color: var(--color-gray-2);
}

.select {
    appearance: none;
    background-image: url("@/assets/images/arrow-dropdown-gray.svg");
    background-position: right 20px center;
    background-repeat: no-repeat;
}

.select:invalid {
    color: var(--color-gray-4);
}

:is(.input, .select, .inputInfo) + :is(.input, .select) {
    margin-top: 20px;
}

.input::placeholder {
    color: var(--color-gray-4);
    font-weight: 500;
}

.inputInfo {
    display: block;
    margin-top: 12px;
    color: var(--color-gray-5);
    font-weight: 400;
}

.checkboxContainer {
    margin-top: 32px;
}

:is(.checkboxItem, .checkboxItemWithInfo) + :is(.checkboxItem, .checkboxItemWithInfo) {
    margin-top: 20px
}

.checkboxItem {
    display: flex;
    align-items: center;
}

.checkbox {
    width: 20px;
    height: 20px;
    vertical-align: baseline;
    margin: 0;
    border: none;
    appearance: none;
    background-color: var(--color-gray-4);
    background-image: url("@/assets/images/checkbox-check.svg");
    background-repeat: no-repeat;
    background-position: center 6px;
    border-radius: 6px;
}

.checkbox:checked {
    background-color: var(--color-main);
}

.checkboxItemDescription {
    font-size: 16px;
    margin-left: 8px;
    line-height: 1;
    color: var(--color-gray-6);
}

.checkboxItemInfo {
    margin-top: 4px;
    margin-left: 28px;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-gray-5);
}

.modalFooter {
    padding: 40px;
    text-align: center;
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 100px;
    width: 320px;
    height: 64px;
    box-sizing: border-box;
    font-size: 24px;
    font-weight: 700;
    background-color: var(--color-main);
    color: var(--color-white);
    cursor: pointer;
}

.button:disabled {
    background: var(--color-gray-2);
    color: var(--color-gray-4);
    cursor: not-allowed;
}

@media screen and (max-width: 768px) {

    .modalHeader {
        padding: 40px 16px 32px 16px;
    }

    .modalHeaderTitle {
        font-size: 24px;
    }

    .modalHeaderSubTitle {
        font-size: 15px;
    }

    .modalBody {
        padding: 0 16px;
    }

    .input, .select {
        padding: 20px 16px;
        height: 56px;
    }

    .inputInfo {
        font-size: 12px;
    }

    .checkboxItemDescription {
        font-size: 16px;
    }

    .checkboxItemInfo {
        font-size: 13px;
    }

    .modalFooter {
        padding: 40px 16px;
    }

    .button {
        font-size: 20px;
    }

}

@media screen and (max-width: 480px) {

    .modalCloseButton {
        position: absolute;
        top: 12px;
        right: 12px;
    }

    .modalContainer {
        padding: var(--unit-header-height) 0 0 0;
        height: 100vh;
        align-items: flex-start;
    }

    .modal {
        height: 100%;
        border-radius: 12px 12px 0 0;
    }

    .modalBody {
        flex: 1;
        overflow: auto;
    }

}
