@keyframes slide {
    100% {
        transform: translateX(-33.333333333%);
    }
}

.section {
    width: 100%;
    background: var(--color-gray-2);
    text-align: center;
    padding: 140px 0;
}

.sectionContent {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: var(--unit-content);
    padding: var(--unit-content-padding);
}

.title {
    color: var(--color-black);
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
    line-height: 1.1666666666;
}

.description {
    color: var(--color-gray-6);
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-top: 0;
    margin-bottom: 60px;
    line-height: 1.5;
}

.article {
    width: calc(var(--unit-content) * 2);
    height: 400px;
    position: relative;
    overflow-x: hidden;
    overflow-y: clip;
    white-space: nowrap;
}

.scroll > .scrollContainer {
    position: absolute;
    height: 100%;
    transform: translate3d(0, 0, 0);
    animation: slide 50s linear infinite;
}

.scroll > .scrollContainerFade {
    position: absolute;
    width: 100%;
    height: 100%;
}

.scroll > .scrollContainerFade::before {
    position: absolute;
    display: block;
    content: "";
    width: 100px;
    height: 100%;
    background: linear-gradient(90deg, var(--color-gray-2), transparent);
    z-index: 1;
}

.scroll > .scrollContainerFade::after {
    position: absolute;
    display: block;
    content: "";
    width: 100px;
    height: 100%;
    right: 0;
    background: linear-gradient(270deg, var(--color-gray-2), transparent);
    z-index: 1;
}

.scroll > .scrollContainer > div + div {
    margin-left: 20px;
}

.swiper {
    display: none;
    width: 380px;
    margin: 0 auto;
}

.swiper :global(.swiper) {
    overflow: visible;
}

.swiperIndices {
    display: none;
    margin-top: 32px;
}

.swiperIndex {
    width: 10px;
    height: 10px;
    display: inline-block;
    background: var(--color-gray-3);
    border-radius: 10px;
}

.swiperIndex + .swiperIndex {
    margin-left: 12px;
}

.swiperIndex.active {
    background: var(--color-main);
}

@media screen and (max-width: 1172px) {

    .article {
        overflow: visible;
        width: auto;
    }

    .scroll {
        display: none;
    }

    .swiper {
        display: block;
    }

    .swiperIndices {
        display: block;
    }

}

@media screen and (max-width: 768px) {

    .section {
        padding: 60px 0;
    }

    .titleIcon {
        width: 60px;
    }

    .title {
        font-size: 32px;
        margin-top: 20px;
        margin-bottom: 16px;
    }

    .description {
        margin-bottom: 32px;
        font-size: 20px;
    }

    .swiper {
        width: 296px;
    }

}

@media screen and (max-width: 480px) {

    .title {
        font-size: 24px;
    }

    .description {
        font-size: 15px;
    }

}
