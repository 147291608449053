.container {
    border-radius: 20px;
    overflow: hidden;
}

.container + .container {
    margin-top: 20px;
}

.title {
    display: flex;
    align-items: center;
    padding: 32px;
    background: var(--color-background-2);
    color: var(--color-white);
    cursor: pointer;
    font-weight: 400;
}

.titleQ {
    font-size: 24px;
    font-weight: 700;
    margin-right: 12px;
    color: var(--color-point-1);
}

.titleText {
    flex: 1;
    font-size: 24px;
    margin-right: 12px;
}

.titleButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 48px;
    width: 48px;
    height: 48px;
    background: var(--color-background-3);
    cursor: pointer;
}

.titleButton img {
    transform: rotateZ(0deg);
    transition: transform 250ms;
}

.titleButton.active img {
    transform: rotateZ(180deg);
}

.contentWrapper {
    display: none;
    padding: 0 32px 32px 32px;
    color: var(--color-gray-4);
    font-size: 20px;
    background: var(--color-background-2);
}

.contentWrapper.active {
    display: block;
}

.content {
    border-top: 1px solid var(--color-background-3);
    padding-top: 32px;
    font-weight: 300;
    line-height: 1.5;
}

@media screen and (max-width: 768px) {

    .container {
        border-radius: 12px;
    }

    .title {
        padding: 16px 12px;
    }

    .titleQ, .titleText {
        font-size: 15px;
    }

    .titleButton {
        width: 24px;
        height: 24px;
    }

    .titleButton img {
        width: 8px;
    }

    .contentWrapper {
        padding: 0 12px 16px 12px;
        font-size: 13px;
    }

    .content {
        padding-top: 16px;
    }

}

@media screen and (max-width: 480px) {


}
